// extracted by mini-css-extract-plugin
export var all_favors_img = "layout-module--all_favors_img--bDIYl";
export var cardCat = "layout-module--cardCat--unRrQ";
export var cardGrid = "layout-module--cardGrid--toLW1";
export var card_sh = "layout-module--card_sh--gKA+S";
export var card_sh_o = "layout-module--card_sh_o--2lRHO";
export var cardl = "layout-module--cardl--et3Vy";
export var container = "layout-module--container--eLBMS";
export var divCenter = "layout-module--divCenter--hHt1y";
export var fitImage = "layout-module--fitImage--ESFuF";
export var footer = "layout-module--footer--rX0Dt";
export var heading = "layout-module--heading--8VjLO";
export var hpmain = "layout-module--hpmain--abHSU";
export var hpmaintxt = "layout-module--hpmaintxt--owhYK";
export var hpslogan = "layout-module--hpslogan--rzLij";
export var iframeCard = "layout-module--iframeCard--5lgR0";
export var iframeSize = "layout-module--iframeSize--5GC0N";
export var inner_cimg = "layout-module--inner_cimg--hG4Ih";
export var jclogoal = "layout-module--jclogoal--RE1vh";
export var jclogoimg = "layout-module--jclogoimg--g2ENK";
export var navBarh = "layout-module--navBarh--ghYp6";
export var navLinkItem = "layout-module--nav-link-item--pfCo2";
export var navLinkText = "layout-module--nav-link-text--ac2nV";
export var navLinks = "layout-module--nav-links--EROwB";
export var selected = "layout-module--selected--aDqbW";
export var siteTitle = "layout-module--site-title--5N6mf";
export var txtBlack = "layout-module--txtBlack--qXuYi";
export var txtbd = "layout-module--txtbd--Zn3vB";
export var txtlight = "layout-module--txtlight--KV9wB";
export var txtlo = "layout-module--txtlo--sacSJ";