import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import {
  container,
  heading,
  navBarh,
  navLinks,
  navLinkItem,
  navLinkText,
  siteTitle,
  selected,
  footer,
  jclogoal,
  jclogoimg
} from '../components/layout.module.css';
import { Helmet } from 'react-helmet';
import jclogo from '../images/jclogo.png';

const Layout = ({ pageTitle, children }) => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div className={container}>

      <Helmet>
        <title>{pageTitle} | {data.site.siteMetadata.title}</title>
        <meta name="description" content="JoanCrafts" />
        <meta name="keywords" content="JoanCrafts,Joan Crafts,joan crafts,Earrings,Coasters,Ashtrays,Jewelry" />
      </Helmet>

      <div className={jclogoal}>
        <Link to="/" className={navLinkText} activeClassName={selected}>
          <img className={jclogoimg} src={jclogo} />
        </Link>
      </div>

      <nav className={navBarh}>
        <ul className={navLinks}>
          <li className={navLinkItem}>
            <Link to="/" className={navLinkText} activeClassName={selected}>
              Αρχική
            </Link>
          </li>


          <li className={navLinkItem}>
            <Link to="/about" className={navLinkText} activeClassName={selected}>
              Σχετικά
            </Link>
          </li>

          <li className={navLinkItem}>
            <Link to="/products" className={navLinkText} activeClassName={selected}>
              Crafts
            </Link>
          </li>

          <li className={navLinkItem}>
            <Link to="/contact" className={navLinkText} activeClassName={selected}>
              Επικοινωνία
            </Link>
          </li>

        </ul>
      </nav>

      <main>
        {children}
      </main>

      <div className={footer}>Copyright Ⓒ 2023 | JoanCrafts</div>

    </div>

  )
}
export default Layout
